import React from 'react'

import styles from './upRating.module.scss'
// import ContestSponsor from '@/components/ContestSponsor/ContestSponsor'
import Button from '@/components/UI/Button/Button'
import TagRadioButton from '@/components/UI/TagRadioButton/TagRadioButton'
import toast from 'react-hot-toast'
import { useTelegram } from '@/context/useTelegramContext'
import { PaymentService } from '@/lib/services/Payment.service'
import { getNumberFromString } from '@/lib/helpers/getNumbersFromString'
import { useMutation } from '@tanstack/react-query'
import { IAxiosBaseError } from '@/lib/types/Api.type'
import { IParticipant } from '@/lib/types/services/Competiton.type'
import { useModal } from '@/context/useModalContext'

interface IUpRating {
	participant: IParticipant
}

const priceList = [
	{
		amount: 100,
		description: `Оплата 100 рублей, поднимает рейтинг участницы на 15 минут`,
	},
	{
		amount: 2000,
		description: 'Оплата 2000 рублей, поднимает рейтинг участницы на 100 минут',
	},
	{
		amount: 5000,
		description: 'Оплата 5000 рублей, поднимает рейтинг участницы на 6 часов.',
	},
]

const UpRating: React.FC<IUpRating> = ({ participant }) => {
	const { openInvoice } = useTelegram()
	const { togglePopup } = useModal()
	const [selectedPrice, setSelectedPrice] = React.useState(
		priceList[0].amount.toString()
	)
	const [isLoading, setIsLoading] = React.useState(false)

	const selectSponsorPrice = (price: string) => {
		console.log('Выбрана цена', price)
		setSelectedPrice(price)
	}

	const paymentMutation = useMutation({
		mutationFn: async () =>
			await PaymentService.addPaymentRecord({
				direction_id: participant.id,
				payment_amount: Number(selectedPrice),
				payment_direction: 'boost',
			}),
		onSuccess(data, variables, context) {
			toast.success('Рейтинг участницы успешно повышен!')
			togglePopup(false)
			setIsLoading(false)
		},
		onError(error: IAxiosBaseError) {
			const {
				response: { data },
			} = error

			toast.error(data.error || 'Возникла ошибка при оплате звездами')
			setIsLoading(false)
		},
	})

	const createPaymentInvoice = () => {
		setIsLoading(true)
		mutate()
	}

	const { mutate } = useMutation({
		mutationFn: async () =>
			await PaymentService.createPaymentInvoice({
				currency: 'XTR',
				amount: Number(getNumberFromString(selectedPrice)),
			}),
		onSuccess(data) {
			const { link } = data
			openInvoice(link, status => {
				console.log(status)
				if (status === 'paid') {
					paymentMutation.mutate()
				} else {
					setIsLoading(false)
				}
			})
		},
		onError(error: IAxiosBaseError) {
			const {
				response: { data },
			} = error

			toast.error(data.error || 'Возникла ошибка при оплате звездами')
			setIsLoading(false)
		},
	})

	const currentPriceItem = React.useMemo(() => {
		return priceList.filter(i => i.amount === Number(selectedPrice))[0]
	}, [selectedPrice])

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<p>Поднять рейтинг</p>
			</div>
			{/* <div className={styles.sponsor}>
				<ContestSponsor />
			</div> */}
			<div className={styles.coastList}>
				{priceList.map((item, index) => (
					<TagRadioButton
						groupName={`price_sponsor`}
						defaultChecked={index === 0}
						key={index}
						value={item.amount}
						id={`price_${index}`}
						text={`${item.amount} ₽`}
						changeHandler={selectSponsorPrice}
					/>
				))}
			</div>
			<div className={styles.bottom}>
				{}
				<div className={styles.hint}>
					<p>{currentPriceItem.description}</p>
				</div>
			</div>
			<div className={styles.footer}>
				<Button
					text='Отправить'
					isLoading={isLoading}
					clickHandler={createPaymentInvoice}
				/>
			</div>
		</div>
	)
}

export default UpRating
