import { $api } from '@/api/api'
import {
	ICreatePaymentInvoice,
	IGetSponsorTopRatingParams,
	IPaymentRecordPayload,
	IResponsePaymentInvoice,
	ISponsorTopRating,
} from '../types/services/Payment.type'
import { IApiResponse } from '../types/Api.type'
import { createQueryStringByParams } from '../helpers/createQueryStringByParams'

class paymentService {
	async createPaymentInvoice(
		payload: ICreatePaymentInvoice
	): Promise<IResponsePaymentInvoice> {
		return (
			await $api.get(
				`/payments/make?currency=${payload.currency}&amount=${payload.amount}`
			)
		).data
	}

	async addPaymentRecord(
		payload: IPaymentRecordPayload
	): Promise<IApiResponse> {
		return await $api.post('/payments', payload)
	}

	async getSponsorTopRating(
		payload: IGetSponsorTopRatingParams,
		apiKey?: string | null
	): Promise<ISponsorTopRating[]> {
		const queryString = createQueryStringByParams(payload)
		return (
			await $api.get(`/payments/top${queryString}`, { headers: { apiKey } })
		).data
	}
}

export const PaymentService = new paymentService()
