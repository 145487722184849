import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { IAppState } from '@/lib/types/store/AppStore.type';
import { UserService } from '@/lib/services/User.service';

export const useAppStore = create<IAppState>()(
    devtools((set, get) => ({
        username: '',
        user_ip: '',
        addUsername: (username) =>
            set((state) => ({
                username,
            })),
        getUserIP: async () => {
            const userIP = get().user_ip;

            if (userIP) return;

            const { ip } = await UserService.getUserIP();

            if (ip) {
                set({ user_ip: ip });
            }
        },
    }))
);
