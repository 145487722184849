import { $api } from '@/api/api'
import {
	IRegistrationParticipation,
	IRegistrationParticipationResponse,
	IUserCompetitionHistory,
	IUserIPResponse,
} from '../types/services/User.type'

class userService {
	async createRequestParticipation(
		payload: IRegistrationParticipation
	): Promise<IRegistrationParticipationResponse> {
		return (await $api.post('/register', payload)).data
	}

	async getUserIP(): Promise<IUserIPResponse> {
		return (await $api.get('/get_ip')).data
	}

	async getCompetitionHistory(): Promise<IUserCompetitionHistory[]> {
		return (await $api.get('/history')).data
	}
}

export const UserService = new userService()
