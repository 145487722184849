import React from 'react'
import { LuLoader } from 'react-icons/lu'

import './miniLoader.scss'

interface IMiniLoader {
	size?: number
	stroke?: string
}

const MiniLoader: React.FC<IMiniLoader> = ({ size = 25, stroke = 'white' }) => {
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<LuLoader size={size} stroke={stroke} className='base-mini-loader' />
		</div>
	)
}

export default MiniLoader
