'use client'

import React from 'react'
import Image from 'next/image'
import { SwiperSlide, Swiper } from 'swiper/react'
import { Zoom } from 'swiper/modules'
import { useSwipeable } from 'react-swipeable'

import 'swiper/css'
import styles from './photoGallery.module.scss'

import BackArrowSVG from '/public/img/backArrow.svg'
import { IGalleryPayload } from '@/lib/types/services/Competiton.type'

interface IPhotoGallery {
	closeHandler: () => void
	payload: IGalleryPayload
}

const PhotoGallery: React.FC<IPhotoGallery> = ({ closeHandler, payload }) => {
	const handlers = useSwipeable({
		onSwipedUp: () => closeHandler(),
	})

	React.useEffect(() => {
		const $adminDashboard = document.querySelector('.admin-dashboard')

		if ($adminDashboard)
			($adminDashboard as HTMLDivElement).style.overflow = 'hidden'

		return () => {
			if ($adminDashboard)
				($adminDashboard as HTMLDivElement).style.overflow = 'auto'
		}
	}, [])

	return (
		<div className={styles.wrapper} {...handlers}>
			<div className={styles.header}>
				<span onClick={closeHandler}>
					<BackArrowSVG />
				</span>
				<p>{payload.full_name}</p>
			</div>

			<div className={styles.gallery}>
				<Swiper zoom={false} modules={[Zoom]}>
					{payload.media.map((img, index) => (
						<SwiperSlide key={index}>
							<div className={styles.galleryItem} key={index}>
								<div className='swiper-zoom-container'>
									<Image
										src={img}
										priority
										width={500}
										height={700}
										alt='photo'
										loading='eager'
									/>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	)
}

export default PhotoGallery
