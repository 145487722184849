import axios from 'axios'
import { getCookie } from 'cookies-next'

export const BASE_API_URL = 'https://contest.lalau.ru/api/'

export const $api = axios.create({
	baseURL: BASE_API_URL,
})

$api.interceptors.request.use(config => {
	if (!config.headers['apiKey']) config.headers['apiKey'] = getCookie('apiKey')

	return config
})
