/**
 * Создаем queryString вида `?id=1&status=ok...` из объекта параметров
 * @param params
 * @returns
 */
export function createQueryStringByParams<T>(params: T): string {
	if (!params) return ''

	let queryString = ''
	if (params) {
		const filtered = Object.keys(params).filter(key => params[key as keyof T])
		queryString = filtered.reduce(
			(prev, current) => `${prev}${current}=${params[current as keyof T]}&`,
			''
		)
		queryString = queryString.slice(0, queryString.length - 1)
	}

	return `?${queryString}`
}
