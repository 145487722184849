import React, { ButtonHTMLAttributes } from 'react'

import './button.scss'

import { LuLoader } from 'react-icons/lu'
import MiniLoader from '../MiniLoader/MiniLoader'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	text: string
	className?: string
	clickHandler?: () => void
	isLoading?: boolean
}

const Button: React.FC<IButton> = ({
	text,
	className,
	clickHandler,
	isLoading,
	...props
}) => {
	return (
		<div className={`btn-base ${className ? className : ''}`}>
			<button
				className='btn'
				disabled={isLoading || props.disabled}
				onClick={clickHandler}
				{...props}
			>
				{isLoading ? <MiniLoader size={20} /> : <>{text}</>}
			</button>
		</div>
	)
}

export default Button
