import { IGalleryPayload, IParticipant } from './services/Competiton.type'

export interface IModalContext {
	isOpenPopup: boolean
	togglePopup: (
		flag: boolean,
		popupType?: PopupTypes,
		payload?: any,
		direction?: PopupDirection
	) => void
}

export interface IGalleryContext {
	isOpenGallery: boolean
	toggleGalleryHandler: (flag: boolean, payload?: IGalleryPayload) => void
	galleryPayload: IGalleryPayload | null
}

export enum PopupTypes {
	UpRating = 1,
}

export enum PopupDirection {
	TopToBottom,
	BottomToTop,
}
