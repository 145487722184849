'use client'

import React, { NamedExoticComponent } from 'react'
import { IGalleryContext } from '@/lib/types'
import PhotoGallery from '@/components/PhotoGallery/PhotoGallery'
import { CSSTransition } from 'react-transition-group'
import {
	IParticipant,
	IGalleryPayload,
} from '@/lib/types/services/Competiton.type'

export const GalleryContext = React.createContext<IGalleryContext>({
	isOpenGallery: false,
	toggleGalleryHandler: () => {},
	galleryPayload: null,
})

let { Provider } = GalleryContext

interface IGalleryContextProvider {
	children: React.ReactNode
}

export const _GalleryContextProvider: NamedExoticComponent<IGalleryContextProvider> =
	React.memo(({ children }) => {
		const [isOpenGallery, toggleGallery] = React.useState<boolean>(false)
		const [galleryPayload, setGalleryPayload] =
			React.useState<IGalleryPayload | null>(null)

		const toggleGalleryHandler = (flag: boolean, payload?: IGalleryPayload) => {
			if (!flag) setGalleryPayload(null)
			if (flag && payload) setGalleryPayload(payload)

			toggleGallery(flag)
		}

		return (
			<Provider
				value={{
					isOpenGallery,
					toggleGalleryHandler,
					galleryPayload,
				}}
			>
				{children}

				<CSSTransition
					in={isOpenGallery}
					timeout={{
						enter: 500,
						exit: 500,
					}}
					classNames={`fade`}
					unmountOnExit
				>
					<PhotoGallery
						closeHandler={() => toggleGallery(false)}
						payload={galleryPayload as IParticipant}
					/>
				</CSSTransition>
			</Provider>
		)
	})

export const useGallery = () => React.useContext(GalleryContext)
