import React from 'react';

import './tagRadioButton.scss';

interface ITagRadioButton {
  groupName: string,
  id: string,
  text: string,
  value: string | number,
  changeHandler: (value: string) => void,
  defaultChecked?: boolean
}

const TagRadioButton : React.FC<ITagRadioButton> = ({ groupName, id, text, value, changeHandler, 
  defaultChecked = false }) => {
  return (
    <div className='tag-radio-button'>
      <input id={id} type='radio' defaultChecked={defaultChecked} onChange={(event) => changeHandler(event.target.value)} name={groupName} hidden value={value} />
      <label htmlFor={id}>{ text }</label>
    </div>
  );
};

export default TagRadioButton;